import { sortBy, uniqBy } from "lodash";

// 将后端数据类型转换成前端需要的格式
export const modalToJson = ({ tableList, levelList }) => {
  const jsonData = {};
  if (tableList) {
    tableList.forEach(table => {
      toTableJson(table, jsonData);
    });
  }
  if (levelList) {
    const json = {};
    const headers = ["等级"];
    const data = [];
    uniqBy(sortBy(levelList, "sort1"), "groupId1").map(level => {
      const header = tableList[0].contentList.find(type => {
        const typeId = tableList[0].typeList.find(
          item => item.content === "等级"
        )?.id;
        return type.groupId === level.groupId1 && type.typeId === typeId;
      })?.content;
      headers.push(header);
    });
    levelList.forEach(level => {
      const { groupId1, groupId2, sort1, sort2, gradeLevel } = level;
      const rowIndex = sort2 - 1;
      const typeId = tableList[1].typeList.find(item => item.content === "等级")
        ?.id;
      if (!data[rowIndex]) {
        const lv = tableList[1].contentList.find(
          item => item.groupId === groupId2 && item.typeId === typeId
        )?.content;
        data[rowIndex] = {
          等级: lv,
          groupId1,
          groupId2
        };
      }
      const typeId2 = tableList[0].typeList.find(
        item => item.content === "等级"
      )?.id;
      const key = tableList[0].contentList.find(item => {
        return item.groupId === groupId1 && item.typeId === typeId2;
      })?.content;
      if (key) {
        data[rowIndex][key] = gradeLevel;
      }
    });
    json.headers = headers;
    json.data = data;
    jsonData.matrix = json;
  }
  return jsonData;
};

const toTableJson = (table, jsonData) => {
  const json = {};
  const headers = [];
  const data = [];
  sortBy(table.typeList, "sort").map(type => {
    // 处理header
    headers.push(type.content);
  });
  table.contentList.forEach(item => {
    const { typeId, sort, content, groupId } = item;
    const rowIndex = sort - 1;
    if (!data[rowIndex]) {
      data[rowIndex] = {
        groupId: groupId
      };
    }
    const key = table.typeList.find(type => type.id === typeId)?.content;
    if (key) {
      data[rowIndex][key] = content;
    }
  });
  json.headers = headers;
  json.data = data;
  jsonData[table.type.replace("LS_", "")] = json;
};

const toLevelModal = (json, levelList, [L, S]) => {
  json.headers
    .filter(header => header !== "等级")
    .forEach((header, index) => {
      json.data.forEach((row, rowIndex) => {
        const groupId1 = L.contentList.find(
          item => item.content?.toString() === header
        )?.groupId;
        const groupId2 = S.contentList.find(
          item => item.content === row["等级"]
        )?.groupId;
        const level = {
          gradeLevel: row[header],
          groupId1: groupId1,
          groupId2: groupId2,
          sort1: index + 1, // 确定表头位置
          sort2: rowIndex + 1 // 确定数据行信息
        };
        levelList.push(level);
      });
    });
};
