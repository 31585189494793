<!--控制风险列表-->
<template>
  <div class="risk-list-box">
    <risk-header
      :current="0"
      :btn-auth="btnAuth.add"
      @change="onTabChange"
      @back="$router.push('/')"
      @add="onAdd"
    ></risk-header>
    <div class="risk-list">
      <div class="risk-top">
        <van-search
          v-model="searchName"
          :placeholder="`请输入${TEXT_CONTROL_RISK_SEARCH_NAME}`"
          class="search-bar"
          @search="onSearch"
          @clear="onCancel"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <riskMenu :menus="menus" @select-menu="selectMenu" />
        <div class="risk-total">
          共{{ total }}个{{ TEXT_CONTROL_RISK_TITLE }}
        </div>
      </div>
      <van-pull-refresh
        ref="vanList"
        v-model="refreshing"
        class="risk-refresh-list"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText || '没有更多了'"
          :error.sync="loadError"
          error-text="加载失败，请稍后再试！"
          @load="onLoad"
        >
          <div
            v-for="(item, index) in list"
            :key="item.id"
            class="risk-item"
            @click="gotoDetail(item.id)"
          >
            <van-swipe-cell
              :right-width="btnAuth.delete ? 70 : 0"
              class="risk-cell"
            >
              <div class="risk-left-cell">
                <div class="risk-factor">
                  <span class="risk-title">{{ item.hazardousFactors }}</span
                  ><span class="risk-status" :class="'_' + item.status">{{
                    item.statusName
                  }}</span>
                </div>
                <div class="risk-info">
                  <span class="risk-info-item"
                    ><i class="iconfont iconfont-fengxiandian1"></i
                    >{{ item.pointName }}</span
                  >
                  <span class="risk-info-item"
                    ><i class="iconfont iconfont-guankongcengji"></i
                    >{{ item.riskHierarchyName }}</span
                  >
                  <span
                    v-if="item.controlRiskLevelName"
                    :class="'risk-control-level _' + item.controlRiskLevel"
                    >{{ item.controlRiskLevelName }}</span
                  >
                </div>
                <div class="risk-info">
                  <span class="risk-info-item"
                    ><i class="iconfont iconfont-quyu1"></i
                    >{{ item.areaName }}</span
                  >
                  <span class="risk-info-item"
                    ><i class="iconfont iconfont-bumen"></i>
                    {{ item.departmentName }}</span
                  >
                </div>
              </div>
              <template v-if="btnAuth.delete" #right>
                <span
                  class="delete-btn iconfont iconfont-shanchutubiao1"
                  @click="del(item, index)"
                ></span>
              </template>
            </van-swipe-cell>
          </div>
        </van-list>
      </van-pull-refresh>
      <cascade-department
        v-model="departmentIn"
        :visible="popupVisibleObj[0].visiable"
        include-children
        @confirm="confirmDepartment"
        @close="popupVisibleObj[0].visiable = false"
      ></cascade-department>

      <SelectPopupGrade
        v-for="item in popupVisibleObjCom"
        :key="item.key"
        :ref="'selectPopupGrade' + item.key"
        v-model="popupVisibleObj[item.key].value"
        v-bind="item.otherProps || {}"
        :immediately="false"
        list-key="value"
        :title="popupVisibleObj[item.key].title"
        :list.sync="popupVisibleObj[item.key].list"
        :visible="popupVisibleObj[item.key].visiable"
        @change="v => popupGradeChange(v, item.key)"
        @close="popupVisibleObj[item.key].visiable = false"
      />
    </div>
  </div>
</template>

<script>
import {
  deleteRisk,
  getAreaSelect,
  getHierarchySelect,
  getLevelSelect,
  getPageList as getList,
  getPointByAreaSelect,
  getStatusSelect
} from "@/api/psm/risk";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import riskMenu from "./riskMenu";
import { mapMutations, mapState } from "vuex";
import RiskHeader from "@/views/risk/components/RiskHeader";
import projectTextConfig from "@/utils/projectTextConfig";

export default {
  name: "ControlRisk",
  components: {
    RiskHeader,
    riskMenu,
    SelectPopupGrade
  },
  data() {
    return {
      popupVisibleObj: {
        0: {
          visiable: false
        },
        1: {
          visiable: false,
          title: projectTextConfig.TEXT_RISK_POINT_LIST_AREA,
          value: "",
          list: []
        },
        2: {
          visiable: false,
          title: projectTextConfig.TEXT_RISK_POINT_LIST_RP,
          value: "",
          list: []
        },
        3: {
          visiable: false,
          title: "风险等级",
          value: "",
          list: [],
          otherProps: {
            filterable: false
          }
        },
        4: {
          visiable: false,
          title: "管控层级",
          value: "",
          list: [],
          otherProps: {
            filterable: false
          }
        },
        5: {
          visiable: false,
          title: "状态",
          value: "",
          list: [],
          otherProps: {
            filterable: false
          }
        }
      },
      menus: [
        {
          text: projectTextConfig.TEXT_RISK_POINT_LIST_AREA,
          value: 1
        },
        {
          text: projectTextConfig.TEXT_RISK_POINT_LIST_RP,
          value: 2
        },
        {
          text: "等级",
          value: 3
        },
        {
          text: "管控",
          value: 4
        },
        {
          text: "状态",
          value: 5
        },
        {
          text: "所属部门",
          value: 0
        }
      ],
      searchName: "",
      total: 0,
      list: [],
      finished: false,
      loading: false,
      finishedText: "",
      refreshing: false,
      loadError: false,
      departmentIn: "",
      query: {
        page: 1,
        size: 10,
        departmentIn: "", // 部门
        subDepartment: true,
        riskAreaId: "", // 区域
        pointId: "", // 风险点
        controlRiskLevel: "", // 等级
        riskHierarchyCode: "", // 管控层级
        statusInList: "" // 状态
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      needRefreshList: state => state.risk.needRefreshList,
      btnAuth: state => state.menu.btnAuth
    }),
    popupVisibleObjCom() {
      const r = [];
      return Object.entries(this.popupVisibleObj)
        .slice(1)
        .map(item => {
          const [key, value] = item;
          return {
            ...value,
            key: +key
          };
        });
    }
  },
  created() {
    this.getSelect();
    this.onSearch();
  },
  methods: {
    ...mapMutations({
      setNeedRefreshList: "setNeedRefreshList"
    }),
    onTabChange(row) {
      console.log(row, "onTabChange");
      this.$router.replace({ name: row.routeName });
    },
    onAdd() {
      this.$router.push({ name: "RiskAdd" });
    },
    del(row, index) {
      this.$dialog
        .confirm({
          title: "",
          message: "确定要删除该数据吗?"
        })
        .then(() => {
          // 执行删除
          deleteRisk(row.id)
            .then(res => {
              if (res) {
                this.$toast.success("删除成功");
                this.total--;
                this.list.splice(index, 1);
              } else {
                this.$toast.fail("删除失败");
              }
            })
            .catch(e => {
              this.$toast.fail("删除失败");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    getSelect() {
      // 获取区域
      getAreaSelect().then(res => {
        this.popupVisibleObj[1].list = res;
      });
      // 获取风险点
      this.getPointByAreaSelect();
      getLevelSelect().then(res => {
        this.popupVisibleObj[3].list = res;
      });
      getHierarchySelect().then(res => {
        this.popupVisibleObj[4].list = res;
      });
      getStatusSelect().then(res => {
        this.popupVisibleObj[5].list = res;
      });
    },
    // 获取风险点
    getPointByAreaSelect(areaId = "") {
      getPointByAreaSelect({ riskAreaId: areaId }).then(res => {
        this.popupVisibleObj[2].list = res;
      });
    },
    popupGradeChange(v, type) {
      const obj = (v && v[0]) || {};
      if (type === 0) {
        this.query.departmentIn = obj.value;
      } else if (type === 1) {
        // 区域
        // 如果这次和上次选择的区域的不是同一个, 则需要清空风险点下拉数据
        if (this.query.riskAreaId !== obj.value) {
          this.query.pointId = "";
        }
        this.query.riskAreaId = obj.value;
        this.getPointByAreaSelect(this.query.riskAreaId);
      } else if (type === 2) {
        // 风险点
        this.query.pointId = obj.value;
      } else if (type === 3) {
        // 等级
        this.query.controlRiskLevel = obj.value;
      } else if (type === 4) {
        // 管控层级
        this.query.riskHierarchyCode = obj.value;
      } else if (type === 5) {
        // 状态
        this.query.statusInList = obj.value;
      }
      this.popupVisibleObj[type].value = obj.value;

      this.onSearch();
    },
    selectMenu(menu) {
      const { value } = menu;
      this.popupVisibleObj[value].visiable = true;
      if (value === 0) {
        // 部门
      } else if (value === 1) {
        // 区域
      } else if (value === 2) {
        // 风险点
      } else if (value === 3) {
        // 等级
      } else if (value === 4) {
        // 管控
      } else if (value === 5) {
        // 状态
      }
    },
    confirmDepartment(selectValue, includeInfo) {
      const dept = this.departmentIn;
      this.query.subDepartment = includeInfo?.include;
      this.popupGradeChange([{ value: dept }], 0);
    },
    onSearch() {
      this.finished = false;
      this.loading = true;
      this.query.page = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    onCancel() {},
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      this.getList();
    },
    mergeBaseParams(params) {
      return {
        ...params
        // orgCode: this.userInfo.orgCode
      };
    },
    getList() {
      getList(
        this.mergeBaseParams({
          ...this.query,
          fuzzyName: this.searchName
        })
      ).then(res => {
        this.loading = false;
        if (res.list && res.list.length) {
          this.total = res.total;
          this.list = this.list.concat(res.list);
          this.query.page++;
        } else {
          if (!this.list.length) {
            this.finishedText = "没有更多了";
          } else {
            this.finishedText = "";
          }
          this.finished = true;
        }
      });
    },
    gotoDetail(id) {
      this.$router.push({ name: "RiskAdd", query: { id } });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      // 将 page 重新设置为 1
      this.query.page = 1;
      this.onLoad();
    },
    scrollTo() {
      this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    }
  },
  activated() {
    console.log("activated -> 控制风险");
    // 去查询列表
    this.$nextTick(() => {
      if (this.$refs.vanList && this.$refs.vanList.$el) {
        this.$refs.vanList.$el.scrollTo(0, this.scrollHeight || 0);
      }
    });
    if (this.needRefreshList) {
      this.onSearch();
      this.setNeedRefreshList({ value: false });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.scrollTo();
    next();
  }
};
</script>

<style lang="scss" scoped>
.risk-list-box {
  height: 100%;
}
.risk-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  .risk-top {
    .risk-total {
      margin-top: 11px;
      font-size: 12px;
      text-align: center;
      color: #8c8f97;
    }
  }
  .risk-refresh-list {
    flex: 1;
    overflow-y: auto;
    .risk-item {
      border-bottom: 8px solid #eceef2;
      .risk-cell {
        .risk-left-cell {
          padding: 13px 16px;
        }
      }
      .risk-factor {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        margin-bottom: 10px;
        .risk-title {
          color: #3b4664;
          flex: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 500;
        }
        .risk-status {
          margin-left: 8px;
          font-weight: 400;
          &._0 {
            color: #f13030;
          }
          &._1 {
            color: #8c8f97;
          }
          &._2 {
            color: #1676ff;
          }
          &._4 {
            color: #ff6d1e;
          }
        }
      }
      .risk-info {
        font-size: 12px;
        color: #3b4664;
        &:not(:last-child) {
          margin-top: 5px;
        }
        .risk-info-item {
          display: inline-block;
          margin-right: 20px;
          margin-bottom: 5px;
          .iconfont {
            color: #aeb3c0;
            margin-right: 5px;
          }
        }
        .risk-control-level {
          height: 16px;
          line-height: 16px;
          font-size: 12px;
          padding: 0 5px;
          border: 1px solid;
          border-radius: 2px;
          &._1 {
            // 及其危险
            color: #f13030;
            border-color: #f13030;
            background-color: #ffe7db;
          }
          &._2 {
            // 高度危险
            color: #ff6d1e;
            border-color: #ff6d1e;
            background-color: #ffdddd;
          }
          &._3 {
            // 显著危险
            color: #eac200;
            border-color: #eac200;
            background-color: #fffbe9;
          }
          &._4 {
            // 轻度危险
            color: #1580ff;
            border-color: #1580ff;
            background-color: #dcecff;
          }
        }
      }
      .delete-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 70px;
        background-color: #f95f5f;
        color: #fff;
        font-size: 28px;
        text-align: center;
      }
    }
  }
}
</style>
