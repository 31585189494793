import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
import { modalToJson } from "@/views/risk/utils/dataTransform";
const psm = apiPath.psm;
const authorization = apiPath.authorization;
const dictionary = apiPath.dictionary;

// 获取控制风险分页列表
export const getPageList = params => {
  return axios.get(`${psm}/page/risk/controls`, { params });
};

// 获取控制风险区域查询条件
export const getAreaSelect = () => {
  return axios.get(`${psm}/select/risk/areas/name`);
};

// 获取控制风险区域风险点
export const getPointByAreaSelect = params => {
  return axios.get(`${psm}/select/risk/points/name`, { params });
};

// 获取控制风险风险等级
export const getLevelSelect = () => {
  return axios.get(`${psm}/dict/select/risk/controls`);
};

// 获取控制风险状态
export const getStatusSelect = () => {
  return axios.get(`${psm}/dict/select/risk/controls/status`);
};

// 获取控制风险管控层级
export const getHierarchySelect = () => {
  return axios.get(`${dictionary}/select/risk/governance/level`);
};

// 获取控制风险删除
export const deleteRisk = id => {
  return axios.post(`${psm}/delete/risk/controls/${id}`);
};

// 获取风险有害因素类型
export const getRiskFactorType = () => {
  return axios.get(`${dictionary}/select/hazard/factor`);
};

// 获取可能导致的事故下拉
export const getRiskAccidentSelect = () => {
  return axios.get(`${psm}/dict/select/risk/accident`);
};

// 新增控制风险
export const saveRisk = params => {
  return axios.post(`${psm}/add/risk/controls`, params);
};

// 查询风险点详情
export const getRiskPointDetail = id => {
  return axios.get(`${psm}/info/risk/points/simple/${id}`);
};

// 查询控制风险后果下拉
export const getConsequenceSelect = id => {
  return axios.get(`${psm}/dict/select/accident/consequence`);
};

// 查询L
export const getRiskL = () => {
  return axios.get(`${psm}/info/risk/controls/table/ls/l`);
};

// 查询S
export const getRiskS = () => {
  return axios.get(`${psm}/info/risk/controls/table/ls/s`);
};

// 查询LEC L
export const getRiskLecL = () => {
  return axios.get(`${psm}/info/risk/controls/table/lec/l`);
};
// 查询LEC E
export const getRiskLecE = () => {
  return axios.get(`${psm}/info/risk/controls/table/lec/e`);
};
// 查询LEC C
export const getRiskLecC = () => {
  return axios.get(`${psm}/info/risk/controls/table/lec/c`);
};

// 查询管控措施
export const getMeasuresSelect = () => {
  return axios.get(`${psm}/dict/select/rt/control/measures`);
};

// 查询控制风险详情
export const getRiskDetail = id => {
  return axios.get(`${psm}/info/risk/controls/${id}`);
};

// 更新控制风险
export const updateRisk = (id, params) => {
  return axios.post(`${psm}/update/risk/controls/${id}`, params);
};

// 岗位下拉
export const getPositionSelect = () => {
  return axios.get(`${authorization}/select/positions`).then(res => {
    if (!res) {
      return [];
    }
    return res.map(v => {
      return {
        value: v.value,
        label: v.departmentName ? `${v.label} (${v.departmentName})` : v.label
      };
    });
  });
};

/**
 * 风险点类型下拉
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getSelectRiskPointType = () =>
  axios.get(psm + "/dict/select/risk/point/type");

/**
 * 风险点列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPageRiskPoint = params =>
  axios.get(psm + "/page/risk/points", { params: { ...params } });

/**
 * 删除风险点
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteRiskPointById = id =>
  axios.post(psm + "/delete/risk/points/" + id);

/**
 * 新增风险点
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const saveRiskPoint = params =>
  axios.post(psm + "/add/risk/points", params);
/**
 * 根据区域获取设备设施列表
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRiskAreaList = params =>
  axios.get(psm + "/select/risk/areas/poi", { params });
/**
 * 作业类型字典
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRiskWorkType = _ => axios.get(psm + "/list/work_type");

/**
 * 风险点详情
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getRiskPointDetail2 = id =>
  axios.get(psm + "/info/risk/points/" + id);
/**
 * 更新风险点
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateRiskPointDetail = (id, params) =>
  axios.post(psm + "/update/risk/points/" + id, params);

/**
 * 管控措施级联
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getControlMeasuresCascadeDic = id =>
  axios.get(psm + "/dict/cascade/control/measures");

// 查询风险分区 自定义表格内容
export const queryRiskAreaGradeTable = (params = {}) => {
  return axios.get(psm + "/info/risk/area/grade/table", { params });
};

// 查询风险分区 自定义等级
export const queryRiskAreaGradeLevel = (params = {}) => {
  return axios.get(psm + "/info/risk/area/grade/level", { params });
};

export const queryRiskAreaLSInfo = async () => {
  const [L, S, matrix] = await Promise.all([
    queryRiskAreaGradeTable({ type: "LS_L" }),
    queryRiskAreaGradeTable({ type: "LS_S" }),
    queryRiskAreaGradeLevel({ type: "LS" })
  ]);
  const json = {
    tableList: [
      {
        type: "LS_L",
        ...L
      },
      {
        type: "LS_S",
        ...S
      }
    ],
    levelList: matrix
  };
  return modalToJson(json);
};
