<template>
  <div class="risk-wrapper">
    <div class="risk-index-top">
      <van-nav-bar left-arrow :border="false" @click-left="$emit('back')">
        <template #title>
          <div class="risk-title-box">
            <span
              v-for="item in tabs"
              :key="item.value"
              :class="{ active: curTab === item.value }"
              class="risk-title-item"
              @click.prevent="$emit('change', item)"
              >{{ item.text }}</span
            >
          </div>
        </template>
        <template #right>
          <i
            v-if="btnAuth"
            class="addiconfont iconfont iconfont-xinzengfengxian"
            @click="$emit('add')"
          ></i>
        </template>
      </van-nav-bar>
    </div>
  </div>
</template>

<script>
import projectTextConfig from "@/utils/projectTextConfig";

export default {
  name: "RiskHeader",
  props: {
    current: {
      type: Number,
      default: 0
    },
    btnAuth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      curTab: 0,
      tabs: [
        {
          text: projectTextConfig.TEXT_CONTROL_RISK_TITLE,
          routeName: "ControlRisk",
          value: 0
        },
        {
          text: projectTextConfig.TEXT_RISK_POINT_TITLE,
          routeName: "RiskPoint",
          value: 1
        }
      ]
    };
  },
  created() {
    this.curTab = this.current;
  }
};
</script>

<style lang="scss" scoped>
.risk-wrapper {
  /*height: 100%;*/
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .van-nav-bar {
    ::v-deep.van-nav-bar__title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .addiconfont {
      font-size: 24px;
    }
  }
  .risk-title-box {
    position: relative;
    display: inline-block;
    width: 160px;
    font-size: 0;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    overflow: hidden;
    .risk-title-item {
      display: inline-block;
      width: 50%;
      height: 100%;
      font-size: 14px;
      color: #fff;
      background-color: #1676ff;
      border: 1px solid #fff;
      box-sizing: border-box;
      &.active {
        color: #1676ff;
        background-color: #fff;
        box-sizing: border-box;
      }
      &:nth-child(1) {
        border-radius: 4px 0 0 4px;
        border-right: none;
      }
      &:nth-child(2) {
        border-radius: 0 4px 4px 0;
        border-left: none;
      }
    }
  }
  .risk-content {
    flex: 1;
    overflow-y: auto;
    .tab-panl {
      position: relative;
      height: 100%;
    }
  }
}
</style>
