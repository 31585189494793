<template>
  <div class="risk-query-menu">
    <span
      v-for="item in menus"
      :key="item.value"
      class="menu-item"
      @click="selectMenu(item)"
    >
      <span class="menu-item-text">{{ item.text }}</span>
    </span>
  </div>
</template>

<script>
import projectTextConfig from "@/utils/projectTextConfig";

export default {
  props: {
    menus: {
      type: Array,
      default: () => [
        {
          text: "所属部门",
          value: 0
        },
        {
          text: projectTextConfig.TEXT_RISK_POINT_LIST_AREA,
          value: 1
        },
        {
          text: projectTextConfig.TEXT_RISK_POINT_LIST_RP,
          value: 2
        },
        {
          text: "等级",
          value: 3
        },
        {
          text: "管控",
          value: 4
        },
        {
          text: "状态",
          value: 5
        }
      ]
    }
  },
  methods: {
    selectMenu(item) {
      this.$emit("select-menu", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.risk-query-menu {
  display: flex;
  font-size: 0;
  padding: 10px 24px 10px 16px;
  border-bottom: 1px solid #c7c9d0;
  justify-content: space-between;
  .menu-item {
    font-size: 14px;
    white-space: nowrap;
    .menu-item-text {
      position: relative;
      color: #3b4664;
      &::after {
        position: absolute;
        content: "";
        top: 50%;
        right: -10px;
        margin-top: -3px;
        border: 4px solid transparent;
        border-top-color: #aeb3c0;
      }
    }
  }
}
</style>
